import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import { v4 } from 'uuid';
import { Button, Label, Segment } from 'semantic-ui-react';
import { format } from 'date-fns';

import Loader from 'components/common/Loader';
import ChatAvatar from '../ChatAvatar';

import FileMessage from '../FileMessage';
import OutogingMessage from '../OutgoingMessage';
import SimpleIncomingMessage from '../SimpleIncomingMessage';
import BlocksIncomingMessage from '../BlocksIncomingMessage';
import MessageThumbnail from '../MessageThumbnail';
import LogMessage from '../LogMessage';
import SenderName from '../SenderName';

import './ConversationsStream.scss';

const ConversationsStream = ({
  messages,
  canLoadMore,
  isLoadingMore,
  loadMore,
  messagesElement,
  onScrollMessages,
}) => {
  const bottomElement = useRef();
  useEffect(() => {
    if (bottomElement) {
      setTimeout(() => {
        bottomElement.current?.scrollIntoView();
      }, 200);
    }
  }, [bottomElement, messages]);

  const groupedMessages = useMemo(() => {
    return groupBy(messages, (message) => new Date(message.timestamp).toDateString());
  }, [messages]);

  const getTimestamp = (timestamp) => {
    return format(new Date(timestamp), 'HH:mm');
  };

  const renderMessages = useCallback((messages) => {
    return messages.map((msg, ix) => {
      let { timestamp, id, message_type, message_subtype, sender_id, message_sender } = msg;

      const senderChange = () => {
        if (
          msg?.first_name !== messages[ix - 1]?.first_name ||
          messages[ix - 1]?.message_type === 'log' ||
          messages[ix - 1]?.message_type === 'audit'
        ) {
          return true;
        } else {
          return false;
        }
      };

      const displayTimestamp = (timestamp, messageType) => {
        if (
          msg?.first_name !== messages[ix + 1]?.first_name ||
          messages[ix + 1]?.message_type === 'log' ||
          messages[ix + 1]?.message_type === 'audit'
        ) {
          if (messageType === 'incoming') {
            return (
              <div
                className="timestamp small-text"
                style={{ display: 'flex', alignItems: 'flex-start' }}
              >
                {getTimestamp(timestamp)}
              </div>
            );
          } else if (messageType === 'outgoing') {
            return (
              <div
                className="timestamp small-text"
                style={{ display: 'flex', alignItems: 'flex-end' }}
              >
                {getTimestamp(timestamp)}
              </div>
            );
          }
        }
      };

      if (message_subtype === 'email transcript') {
        return null;
      }

      if (message_type === 'log') {
        let {
          message: { log_message },
        } = msg;
        return (
          <LogMessage key={id} timestamp={timestamp} messageType={message_type}>
            {log_message}
          </LogMessage>
        );
      }
      if (message_type === 'audit') {
        let {
          message: { audit_message },
        } = msg;
        return (
          <LogMessage key={id} timestamp={timestamp} messageType={message_type}>
            {audit_message}
          </LogMessage>
        );
      }

      let {
        message: {
          parsedMessage: { blocks },
          parsedMessage,
          is_message_from_user,
          file,
          fileType,
        },
      } = msg;

      if (is_message_from_user) {
        if (parsedMessage === 0) {
          const newMessage = {
            id: v4(),
            type: 'incoming',
            content: parsedMessage,
            meta: { ...parsedMessage },
            alternativeText: `${parsedMessage}`,
          };

          return (
            <MessageThumbnail key={id} timestamp={timestamp} position="right center">
              {senderChange() && (
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '22px' }}>
                  <ChatAvatar msgSender={message_sender} />
                  <SenderName senderID={sender_id} style={{ marginLeft: '5px' }} />
                </div>
              )}
              <SimpleIncomingMessage key={id} message={newMessage} senderID={sender_id} />
              <FileMessage fileUrl={file} fileType={fileType} senderID={sender_id} />
              {displayTimestamp(timestamp, newMessage.type)}
            </MessageThumbnail>
          );
        } else if (blocks === undefined) {
          const newMessage = {
            id: v4(),
            type: 'incoming',
            content: parsedMessage,
            meta: { ...parsedMessage },
            alternativeText: `Message not available`,
          };

          return (
            <MessageThumbnail key={id} timestamp={timestamp} position="right center">
              {senderChange() && (
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '22px' }}>
                  <ChatAvatar msgSender={message_sender} />
                  <SenderName senderID={sender_id} style={{ marginLeft: '5px' }} />
                </div>
              )}
              <SimpleIncomingMessage key={id} message={newMessage} senderID={sender_id} />
              <FileMessage fileUrl={file} fileType={fileType} senderID={sender_id} />
              {displayTimestamp(timestamp, newMessage.type)}
            </MessageThumbnail>
          );
        } else {
          return blocks?.map((block, index) => {
            const { text, titleLabel, alternative_text, carousel_elements = [] } = block;
            const newMessage = {
              id: v4(),
              type: 'incoming',
              content: text || titleLabel || carousel_elements[0]?.text,
              meta: { ...block },
              alternativeText: alternative_text,
            };

            return (
              <MessageThumbnail key={index} timestamp={timestamp} position="right center">
                {index === 0 && senderChange() && (
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '22px' }}>
                    <ChatAvatar msgSender={message_sender} />
                    <SenderName senderID={sender_id} style={{ marginLeft: '5px' }} />
                  </div>
                )}
                <BlocksIncomingMessage
                  key={`msg_${index}`}
                  message={newMessage}
                  senderID={sender_id}
                />
                <FileMessage fileUrl={file} fileType={fileType} senderID={sender_id} />
                {index === blocks.length - 1 && displayTimestamp(timestamp, newMessage.type)}
              </MessageThumbnail>
            );
          });
        }
      } else {
        return blocks?.map((block, index) => {
          const { linkText, text, titleLabel, alternative_text, carousel_elements = [] } = block;

          const newMessage = {
            id: v4(),
            type: 'outgoing',
            content: linkText || text || titleLabel || carousel_elements[0]?.text,
            meta: { ...block },
            alternativeText: alternative_text,
          };

          return (
            <MessageThumbnail key={index} timestamp={timestamp} position="left center">
              {index === 0 && (
                <SenderName
                  style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '8px' }}
                  senderID={sender_id}
                />
              )}
              <OutogingMessage key={`msg_${index}`} message={newMessage} senderID={sender_id} />
              <FileMessage
                key={`file_${index}`}
                fileUrl={file}
                fileType={fileType}
                senderID={sender_id}
              />
              {index === blocks.length - 1 && displayTimestamp(timestamp, newMessage.type)}
            </MessageThumbnail>
          );
        });
      }
    });
  }, []);

  const renderConversationStream = () => {
    const today = new Date().toDateString();

    return (
      <div className="Messages" ref={messagesElement}>
        {canLoadMore && (
          <div className="LoadMore">
            {!isLoadingMore && <Button onClick={loadMore}>Load more</Button>}
            {isLoadingMore && <Loader />}
          </div>
        )}
        {keys(groupedMessages).map((date) => {
          return (
            <React.Fragment key={date}>
              <Segment className="DateDivider" textAlign="center" basic>
                <Label className="date-divider-label small-text">
                  {today === date ? 'Today' : date}
                </Label>
              </Segment>
              {renderMessages(groupedMessages[date])}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <div id="conversation-stream" className="ConversationStream" onScroll={onScrollMessages}>
      {renderConversationStream()}
      <div className="_bottomElement" ref={bottomElement} />
    </div>
  );
};

export default ConversationsStream;
