import React, { Component } from 'react';
import Axios from 'axios';
import { produce } from 'immer';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import Loader from 'components/common/Loader';
import Dialog from 'pages/Flow/components/Dialog';
import NameInput from 'pages/Flow/components/NameInput';
import Tabs from 'components/common/TabBar';
import DeleteButton from '../components/DeleteButton';
import Sections from 'components/common/Sections';
import MessageBlocks from 'pages/Flow/components/Message/MessageBlocks';
import VerticalOrderedList from 'pages/Flow/components/Message/VerticalOrderedList';
import ConnectionsWrapper from 'pages/Flow/containers/ConnectionsWrapper';
import CallcenterErrorHandlingMessages from 'pages/Flow/components/CallcenterErrorHandlingMessages';
import withNotifications from 'components/withNotifications';
import { ThemeContext } from 'pages/Flow/utils/Theme';
import './MessageDialog.scss';
import updateMessage from '../../../../../../../../services/updateMessage';
import { Dropdown } from 'semantic-ui-react';

import languageMap from '../../../../../../../../Language/Language';

const ACTION_OPTIONS = [
  'validate_previous_recognition',
  'invalidate_previous_recognition',
  'hide-chat-input-console',
  'end_conversation_no_feedback',
  'end_conversation',
  'assign_to_wisevoice_crm',
  'assign_to_wisevoice_crm:[department_id]',
  'assign_to_zendesk_crm',
  'change_conversation_language:[language]',
  'request-feedback',
  'mark_unmatched_message',
];
class MessageDialog extends Component {
  static contextType = ThemeContext;

  state = {
    automaticTranslation: false,
    responseOptionsByLanguage: {},
    responseOptions: [],
    timeoutResponseOptions: [],
    timeoutResponseOptionsByLanguage: {},
    replySuggestions: [],
    replySuggestionsByLanguage: {},
    actions: [],
    isSuggestionsListTemplate: false,
    min_listen_time: 1,
    no_response_timeout: 1.1,
    time_exceeded: 10,
    isLoading: true,
    isLoadingRequest: false,
  };

  componentDidMount() {
    const { showError, projectId, appId, messageId } = this.props;
    this.setState({ isLoading: true });
    Axios.get('/internal_api/project/integration/data', {
      params: {
        project_id: projectId,
        type_id: 2,
      },
    })
      .then((response) => {
        const data = JSON.parse(response.data.data);
        this.setState(
          produce((draft) => {
            draft.voiceInteraction = data.voice_interaction;
          })
        );
      })
      .catch((e) => {
        showError(e.message);
      });
    Axios.get('/internal_api/message', {
      params: { app_id: appId, message_id: messageId },
    })
      .then((response) => {
        this.extractSettings(response);
        this.setState({ isLoading: false, selectedLanguage: this.props.selectedLanguage });
      })
      .catch((e) => {
        showError(e.message);
        this.setState({ showError: false });
      });
  }

  loadingChange = (value) => {
    this.setState({ isLoadingRequest: value });
  };

  extractSettings(response) {
    const messageData = get(response, 'data.message_details.message_properties');

    this.setState({
      responseOptions: messageData.responseOptions,
      responseOptionsByLanguage: messageData.responseOptionsByLanguage || {},
      timeoutResponseOptionsByLanguage: messageData.timeoutResponseOptionsByLanguage || {},
      replySuggestions: messageData.replySuggestions,
      replySuggestionsByLanguage: messageData.replySuggestionsByLanguage || {},
      actions: messageData.actions,
      isSuggestionsListTemplate: messageData.isSuggestionsListTemplate,
      min_listen_time: messageData.min_listen_time || 1,
      no_response_timeout: messageData.no_response_timeout || 1.1,
      time_exceeded: messageData.time_exceeded || 10,
      automaticTranslation:
        messageData.automaticTranslation !== undefined ? messageData.automaticTranslation : true,
    });
  }

  onElementChanged = (element) => (value) => {
    this.setState(
      produce((draft) => {
        draft[element] = value;
      }),
      () => {
        this.stateSync();
      }
    );
  };

  onOptionsByLanguageChange = (element) => (value) => {
    this.setState(
      produce((draft) => {
        draft[element] = {
          ...draft[element],
          [draft.selectedLanguage]: value,
        };
      }),
      () => {
        this.stateSync(true);
      }
    );
  };

  onParameterChange = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      () => {
        this.debouncedStateSync();
      }
    );
  };

  onChangeAutomaticTranslation = (value) => {
    this.onParameterChange('automaticTranslation', value);
  };

  stateSync = async (doTranslate) => {
    const { projectId, messageId } = this.props;
    const automaticTranslation = this.state.automaticTranslation;
    const properties = { ...this.state };
    delete properties.selectedLanguage;

    if (automaticTranslation && doTranslate) {
      this.setState({ isLoadingRequest: true });
    }
    const response = await updateMessage(
      properties,
      messageId,
      projectId,
      automaticTranslation && doTranslate,
      doTranslate
    );
    if (automaticTranslation && doTranslate) {
      this.setState({ isLoadingRequest: false });
      this.extractSettings(response);
    }
  };

  debouncedStateSync = debounce(this.stateSync, 500);

  deleteNode = () => {
    this.props.onClose();
    this.props.deleteNode(this.props.nodeId);
  };

  onChangeLanguage = (e, { value }) => {
    this.setState({ selectedLanguage: value });
  };

  render() {
    const {
      isLoading,
      voiceInteraction,
      replySuggestionsByLanguage,
      actions,
      timeoutResponseOptionsByLanguage,
      min_listen_time,
      time_exceeded,
      no_response_timeout,
      automaticTranslation,
      selectedLanguage,
      responseOptionsByLanguage,
      isLoadingRequest,
    } = this.state;

    const {
      projectId,
      onClose,
      appId,
      messageId,
      nodeId,
      onNameChange,
      objectName,
      languageOptions,
    } = this.props;

    const currentResponseOptions = responseOptionsByLanguage[selectedLanguage];
    const currentReplySuggestions = replySuggestionsByLanguage[selectedLanguage];
    const currentTimeoutResponseOptions = timeoutResponseOptionsByLanguage[selectedLanguage];

    return (
      <Dialog
        projectId={projectId}
        onClose={onClose}
        isLoading={isLoadingRequest}
        headerView={
          <>
            <NameInput
              appId={appId}
              id={messageId}
              type="message"
              nodeId={nodeId}
              onNameChange={onNameChange}
              value={objectName}
            />
            {languageOptions.length > 1 && (
              <Dropdown
                className="languageDropdown"
                selection
                value={selectedLanguage}
                options={languageOptions}
                onChange={this.onChangeLanguage}
              />
            )}
            <DeleteButton
              title={languageMap.deleteMessage}
              type="message"
              onClick={this.props.deleteNode ? this.deleteNode : null}
            />
          </>
        }
        contentView={
          isLoading ? (
            <Loader />
          ) : (
            <div className="MessageDialog">
              <Tabs>
                <div label={languageMap.chatBotLabel}>
                  <Sections>
                    <MessageBlocks
                      appId={appId}
                      messageId={messageId}
                      projectId={projectId}
                      options={currentResponseOptions}
                      voiceInteraction={voiceInteraction}
                      automaticTranslation={automaticTranslation}
                      onChangeAutomaticTranslation={this.onChangeAutomaticTranslation}
                      onChange={this.onOptionsByLanguageChange('responseOptionsByLanguage')}
                      selectedLanguage={selectedLanguage}
                      loadingChange={this.loadingChange}
                    />
                    <div>
                      <VerticalOrderedList
                        title={languageMap.suggestionsLabel}
                        elements={currentReplySuggestions || []}
                        onChange={this.onOptionsByLanguageChange('replySuggestionsByLanguage')}
                        selectedLanguage={selectedLanguage}
                      />
                    </div>
                    <div className="MessageDialog_ActionsContainer">
                      <VerticalOrderedList
                        title={languageMap.messageActions}
                        elements={actions}
                        options={ACTION_OPTIONS}
                        onChange={this.onElementChanged('actions')}
                      />
                    </div>
                  </Sections>
                </div>
                <div label={languageMap.callCenterLabel}>
                  <CallcenterErrorHandlingMessages
                    title={languageMap.messageTimeoutOptions}
                    description={languageMap.youCanInputDifferentTextResponsesBelow}
                    timeoutResponseOptions={currentTimeoutResponseOptions || []}
                    minListenTime={min_listen_time}
                    noResponseTimeout={no_response_timeout}
                    timeExceeded={time_exceeded}
                    onParameterChange={this.onParameterChange}
                    selectedLanguage={selectedLanguage}
                    onMessageOptionsChange={this.onOptionsByLanguageChange(
                      'timeoutResponseOptionsByLanguage'
                    )}
                  />
                </div>
                {this.props.hideConnections ? (
                  <div label={languageMap.connectionsLabel}>{languageMap.connectionsHide}</div>
                ) : (
                  <div label={languageMap.connectionsLabel}>
                    <ConnectionsWrapper nodeId={this.props.nodeId} />
                  </div>
                )}
              </Tabs>
            </div>
          )
        }
      />
    );
  }
}

export default withNotifications(MessageDialog);
